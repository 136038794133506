import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "Ressources humaines",
    path: "accueil / nos métiers / Ressources humaines / Chargé de recrutement"
  },
  title: "Chargé de recrutement",
	missions:[
		{
		  text: "Analyser et synthétiser le brief rédigé par le consultant et/ou le client, pour comprendre les enjeux du poste." 
		},
		{
		  text: "Faire émerger des profils potentiels de candidats, proposer une stratégie de recherche de candidatures : annonce, approche directe..." 
		},
	],
	key_skills: [
		{
		  text: "Connaissance générale de l’organisation et du fonctionnement d’une entreprise" 
		},
		{
		  text: "Bonne culture économique et connaissance des secteurs d’activités pour lesquels il recrute majoritairement" 
		},
		{
		  text: "Maîtrise des techniques d’entretien, notamment téléphonique, afin d’évaluer rapidement et avec pertinence les candidats contactés" 
		},
		{
		  text: "Connaissance parfaite de la jurisprudence encadrant l’approche directe" 
		},
		{
		  text: "Pratique courante des outils bureautiques, d’internet, du sourcing, du e-sourcing (notamment CVthèques, réseaux sociaux), des logiciels de gestion des CV et de reporting" 
		},
		{
		  text: "Maîtrise de l’anglais et si possible d’une autre langue" 
		},
	],
	prerequisites:[
		{
		  text: "Formation Bac +2 avec une orientation administrative (secrétariat/assistanat) ou commerciale (BTS)" 
		},
	],
	activities:[
		{
		  text: "Mise en place de la stratégie de recherche sourcing" 
		},
		{
		  text: "Identifier les supports de sourcing les plus adaptés à la cible." 
		},
		{
		  text: "Rédiger et diffuser l’offre sur l’ensemble des supports : site internet, réseaux sociaux, sites emplois" 
		},
		{
		  text: "Rechercher les profils adéquats dans les CVthèques" 
		},
		{
		  text: "Identifier les entreprises cibles pouvant abriter le profil recherché et dans leurs organigrammes les profils correspondant au cahier des charges défini avec le client" 
		},
		{
		  text: "Approche et sélection des candidats potentiels" 
		},
		{
		  text: "Sélectionner les candidatures reçues" 
		},
		{
		  text: "Contacter les candidats potentiels et valider leur adéquation au profil défini : motivations, compétences techniques, prétentions salariales..." 
		},
		{
		  text: "Chasser les profils identifiés au sein des entreprises cibles : présentation du poste, validation de la motivation, évaluation de l’adéquation au profil, proposition d’un entretien au sein du cabinet" 
		},
		{
		  text: " Veille et suivi administratif de la mission" 
		},
		{
		  text: "Prendre les rendez-vous pour les consultants" 
		},
		{
		  text: "Assurer le reporting auprès du consultant et/ou du client sur le déroulement de la mission : production de tableaux de synthèse (progress report), analyse qualitative des candidats contactés, difficultés rencontrées..." 
		},
		{
		  text: "Constituer un vivier de candidatures pour le cabinet (bases de données)." 
		},
		{
		  text: "Assurer, sur l’ensemble des supports, une veille active permettant une présence permanente du cabinet sur Internet et une veille économique sur les clients actifs, les prospects, la concurrence du cabinet." 
		},
		{
		  text: "Transmettre aux consultants toute information susceptible d’être exploitée sur le plan commercial" 
		}
	]

}
const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;